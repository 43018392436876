'use client';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox } from '@edeeone/juan-core/components/form/checkbox/Checkbox';
import { FormGroup } from '@edeeone/juan-core/components/form/formGroup/FormGroup';
import { Input } from '@edeeone/juan-core/components/form/input/Input';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useCheckPasswordStrengthMutation } from '../graphql/user-profile/requests/checkPasswordStrength.codegen';
import { passwordInputClassNames } from './PasswordInput.styles';
const emptyCallback = () => { };
export const PasswordInput = ({ variableName, showPasswordStrength = false, completePasswordStrengthIndicator = false, setIsPasswordAcceptable, label, visibleLabel, afterInput, placeholder = '', }) => {
    const finalStyles = passwordInputClassNames();
    const { t } = useI18n('PasswordInput');
    const [checkPasswordStrength, { data }] = useCheckPasswordStrengthMutation();
    const [passwordStrength, setPasswordStrength] = useState(0); // 1 weakest, 6 strongest
    const [visiblePasswordStrength, setVisiblePasswordStrength] = useState(false);
    const [visiblePassword, setVisiblePassword] = useState(false);
    const [inputContent, setInputContent] = useState('');
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [passwordRequirements, setPasswordRequirements] = useState({
        length: false,
        lowerCaseAndUpperCaseCharacter: false,
        numericCharacter: false,
        specialCharacter: false,
    });
    const { watch } = useFormContext();
    const password = watch(variableName);
    useEffect(() => {
        if (password && showPasswordStrength) {
            setInputContent(password);
            checkPasswordStrength({
                variables: {
                    input: {
                        password,
                    },
                },
            });
        }
    }, [password, showPasswordStrength, checkPasswordStrength]);
    useEffect(() => {
        if (data?.checkPasswordStrength?.passwordState) {
            const { length = 0, lowerCaseCharacterCount = 0, numericCharacterCount = 0, score = 0, specialCharacterCount = 0, upperCaseCharacterCount = 0, } = data?.checkPasswordStrength.passwordState;
            setPasswordRequirements({
                length: length >= 8,
                lowerCaseAndUpperCaseCharacter: lowerCaseCharacterCount > 0 && upperCaseCharacterCount > 0,
                numericCharacter: numericCharacterCount > 0,
                specialCharacter: specialCharacterCount > 0,
            });
            setPasswordStrength(Math.floor((score || 0) / 20) + 1);
        }
        if (data?.checkPasswordStrength &&
            typeof setIsPasswordAcceptable === 'function') {
            setIsPasswordAcceptable(!Boolean(data?.checkPasswordStrength?.securityPolicyErrorMessage?.messageCode));
            const message = data?.checkPasswordStrength?.securityPolicyErrorMessage;
            setErrorMessage(message?.messageCode &&
                t(message.messageCode, {
                    firstArgument: (message.messageArgs || [])[0],
                    secondArgument: (message.messageArgs || [])[1],
                }));
        }
    }, [data]);
    function handlePasswordChange(event) {
        setInputContent(event.target.value.trim());
        if (showPasswordStrength) {
            checkPasswordStrength({
                variables: {
                    input: {
                        password: event.target.value,
                    },
                },
            });
        }
    }
    function handlePasswordVisibility() {
        setVisiblePassword(!visiblePassword);
    }
    const handleFocus = () => {
        setVisiblePasswordStrength(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormGroup, { label: label, visibleLabel: visibleLabel, name: variableName, styles: {
                    group__custom: showPasswordStrength && visiblePasswordStrength
                        ? finalStyles.formGroup
                        : '',
                }, onFocus: showPasswordStrength ? handleFocus : undefined, forcedErrorMessage: errorMessage, children: _jsx(Input, { type: visiblePassword ? 'text' : 'password', onChange: handlePasswordChange, placeholder: placeholder, afterInput: _jsxs(_Fragment, { children: [_jsx("span", { className: twMergeJuan(finalStyles.passwordVisibility, inputContent.length === 0 &&
                                    finalStyles.passwordVisibility__disabled), tabIndex: -1, onClick: handlePasswordVisibility, title: t('visibilityTitle'), children: _jsx(SvgSpriteIcon, { icon: visiblePassword ? 'eyeOff' : 'eye', className: twMergeJuan(finalStyles.passwordVisibility_ico, inputContent.length > 0 &&
                                        finalStyles.passwordVisibility_ico__enabled) }) }), afterInput] }) }) }), showPasswordStrength && visiblePasswordStrength && (_jsxs("div", { className: finalStyles.passwordStrengthContainer, children: [_jsx("div", { className: finalStyles.passwordStrengthMeterContainer, children: _jsx("progress", { className: twMergeJuan(finalStyles.passwordStrengthMeter, passwordStrength > 0 &&
                                passwordStrength < 3 &&
                                finalStyles.passwordStrengthMeter_color1, passwordStrength > 2 &&
                                passwordStrength < 5 &&
                                finalStyles.passwordStrengthMeter_color2, passwordStrength > 4 && finalStyles.passwordStrengthMeter_color3), max: "6", value: passwordStrength }) }), passwordStrength !== 0 && (_jsx("p", { className: finalStyles.passwordStrengthLabel, children: t(`strength${passwordStrength}`) })), completePasswordStrengthIndicator && (_jsxs("div", { className: finalStyles.passwordStrengthIndicator, children: [_jsx(Checkbox, { checked: passwordRequirements.length, disabled: true, onChange: emptyCallback, styles: {
                                    container__custom: finalStyles.passwordStrengthIndicator_item,
                                }, children: t('atLeastEightCharacters') }), _jsx(Checkbox, { checked: passwordRequirements.lowerCaseAndUpperCaseCharacter, disabled: true, onChange: emptyCallback, styles: {
                                    container__custom: finalStyles.passwordStrengthIndicator_item,
                                }, children: t('upperAndLowercaseLetter') }), _jsx(Checkbox, { checked: passwordRequirements.numericCharacter, disabled: true, onChange: emptyCallback, styles: {
                                    container__custom: finalStyles.passwordStrengthIndicator_item,
                                }, children: t('number') }), _jsx(Checkbox, { checked: passwordRequirements.specialCharacter, disabled: true, onChange: emptyCallback, styles: {
                                    container__custom: finalStyles.passwordStrengthIndicator_item,
                                }, children: t('specialCharacter') })] }))] }))] }));
};
