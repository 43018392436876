import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const alertDefaultClassNames = /* tw */ {
    container: 'alert w-full rounded-md flex justify-center bg-opacity-10 items-center text-gray-70',
    container__custom: '',
    container__note: 'mb-12',
    text: 'alert__text text-gray-70',
    note: 'alert__note text-sm text-gray-70',
    note__centered: 'text-center',
    icon: 'alert__ico ico grow-0 shrink-0',
    success: {
        container: 'bg-success bg-opacity-10',
        icon: 'svg-success',
    },
    error: {
        container: 'bg-error',
        icon: 'svg-error',
    },
    warn: {
        container: 'bg-warning',
        icon: 'svg-warning',
    },
    info: {
        container: 'bg-blue',
        icon: 'svg-info',
    },
    lg: {
        container: 'py-6 px-8 xs:p-10 xsm:p-12 text-xs xs:text-sm xsm:text-base font-normal xs:font-medium gap-6 leading-[2.6rem]',
        text: 'text-xs xs:text-sm xsm:text-base font-normal xs:font-medium leading-[2.6rem]',
        note: 'mb-20 max-content',
        icon: 'w-[1.8rem] h-[1.8rem] xs:w-[2.8rem] xs:h-[2.8rem]',
    },
    md: {
        container: 'p-10 text-sm font-medium gap-6',
        text: 'text-sm font-medium',
        note: 'mb-6',
        icon: 'w-[2.8rem] h-[2.8rem]',
    },
    sm: {
        container: 'py-6 px-8 text-xs font-normal gap-6',
        text: 'text-xs font-normal',
        note: 'mb-6',
        icon: 'w-[1.8rem] h-[1.8rem]',
    },
};
export function alertClassNames(...args) {
    return getMemoizedFinalClassnames('alertClassNames', alertDefaultClassNames, ...args);
}
