import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { alertClassNames } from './Alert.styles';
export const Alert = ({ type, text, note, styles, size = 'lg', centerNote = false, icon, children, }) => {
    const finalStyles = alertClassNames(styles, 
    // @ts-ignore
    type, size);
    const getIcon = (type) => {
        switch (type) {
            case 'success':
                return 'checkCircle';
            case 'error':
                return 'error';
            case 'warn':
                return 'warning';
            case 'info':
                return 'infoCircle';
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { "aria-label": "Alert", className: twMergeJuan(finalStyles.container, finalStyles.container__custom, note && finalStyles.container__note), children: [_jsx(SvgSpriteIcon, { icon: icon || getIcon(type), className: finalStyles.icon, "aria-hidden": true }), text && (_jsx(_Fragment, { children: (typeof text === 'string' && (_jsx("p", { className: finalStyles.text, children: text }))) || _jsx(_Fragment, { children: text }) })), children] }), note && (_jsx("p", { className: twMergeJuan(finalStyles.note, centerNote && finalStyles.note__centered), children: note }))] }));
};
