import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const passwordInputDefaultClassNames = /* tw */ {
    formGroup: '!mb-0',
    passwordStrengthContainer: 'password-strength mb-8',
    passwordStrengthMeterContainer: 'password-strength__meter-container mb-2 relative overflow-hidden after:absolute after:top-0 after:left-0 after:right-0 after:w-full after:h-full after:block',
    passwordStrengthMeter: 'password-strength__meter h-[4px] w-full bg-gray-10 appearance-none',
    passwordStrengthMeter_color1: 'color-1',
    passwordStrengthMeter_color2: 'color-2',
    passwordStrengthMeter_color3: 'color-3',
    passwordStrengthLabel: 'password-strength__label text-right mb-6 text-xs text-gray-70 font-medium',
    passwordStrengthIndicator: 'password-strength__indicator flex flex-wrap gap-4 pointer-events-none',
    passwordStrengthIndicator_item: 'password-strength__indicator-item w-[calc(50%_-_.5rem)]',
    passwordVisibility: 'password-eye mr-10 inline-block',
    passwordVisibility__disabled: 'pointer-events-none',
    passwordVisibility_ico: 'password-eye__ico w-7 h-7 fill-gray-20',
    passwordVisibility_ico__enabled: 'fill-gray-60 hover:fill-black transition-common cursor-pointer',
};
export function passwordInputClassNames(...args) {
    return getMemoizedFinalClassnames('passwordInputClassNames', passwordInputDefaultClassNames, ...args);
}
