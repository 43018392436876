import { gql } from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client/react/hooks';
const defaultOptions = {};
export const CheckPasswordStrengthDocument = gql `
    mutation checkPasswordStrength($input: CheckPasswordStrengthInput!) {
  checkPasswordStrength(input: $input) {
    passwordState {
      length
      lowerCaseCharacterCount
      numericCharacterCount
      score
      specialCharacterCount
      upperCaseCharacterCount
    }
    securityPolicyErrorMessage {
      messageCode
      messageArgs
    }
  }
}
    `;
/**
 * __useCheckPasswordStrengthMutation__
 *
 * To run a mutation, you first call `useCheckPasswordStrengthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckPasswordStrengthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkPasswordStrengthMutation, { data, loading, error }] = useCheckPasswordStrengthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckPasswordStrengthMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(CheckPasswordStrengthDocument, options);
}
